@import '../Page/App.scss';

.about-page {
  .nav-menu {
    flex-direction: row;
    justify-content: center;
  }

  .nav-link_home {
    display: block;
  }
}

.about-page-text {
  ul {
    li {
      list-style-type: disc;
      margin: 5px 0 5px 20px;
      line-height: 25px;
    }
  }

  a {
    color: #c0a375;
    text-decoration: none;
  }

  p {
    line-height: 25px;
  }

  @include below($mob) {
    padding: 0 10px;
  }
}
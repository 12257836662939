@import "../App.scss";

.consultation {
    height: auto;
    background-color: #e3e4e5;
    position: relative;
    display: flex;
    flex-direction: column;

    @include below-height($vertcl-min) {
        height: auto;
    }

    @include below($mob) {
        height: auto;
    }

    &__title {
        display: flex;
        padding: 60px 0 50px 100px;

        @include below($mob) {
            padding: 30px 15px 15px 15px;
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            width: 100% !important;
        }

        &-text {
            font-size: 28px;
            color: #5a6467;
            margin-bottom: 20px;
            width: 100% !important;
            font-family: "kelson sans ruregular";

            @include above($de) {
                font-size: 32px;
            }

            @include above($full) {
                font-size: 37px;
            }

            @include below($mob) {
                font-size: 20px;
                background-color: #565f62;
                color: #ffffff;
                display: inline-block;
                width: 82% !important;
                padding: 10px 20px;
                font-family: "kelson sans ruregular";
                margin-left: -15px;
                margin-bottom: 35px;
            }
        }

        &-subtext {
            font-family: "kelson sans rulight";
            color: #5a6467;
            font-size: 18px;

            @include above($de) {
                font-size: 22px;
            }

            @include above($full) {
                font-size: 25px;
            }

            @include below($mob) {
                margin-bottom: 25px;
            }
        }
    }

    &__footer {
        display: flex;
        width: 100%;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
    }

    .slick-slider {
        width: 100%;

        .slick-track {
            display: flex;

            .slick-slide {
                flex: 1;
                margin: auto 5px;
            }
        }
    }

    &__stories {
        display: flex;
        align-items: center;
        margin-bottom: 115px;

        @include below($mob) {
            margin-bottom: 100px;
        }

        @include below-height($vertcl-min) {
            margin-bottom: 115px;
            justify-content: center;
        }

        &-wrapper {
            display: flex;
            width: 23%;
            height: auto;
        }

        &-img {
            width: 100%;
            height: 220px;
            object-fit: cover;

            @include above($de) {
                height: 250px;
            }

            @include above($full) {
                height: 280px;
            }
        }

        &-body {
            background: white;
            display: flex;
            padding: 10px;
            flex-direction: column;
        }

        &-title {
            text-decoration: none;
            color: #000;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
            min-height: 36px;
        }

        &-text {
            font-family: "kelson sans ruregular";
            text-decoration: none;
            color: #5a6467;
            font-size: 14px;
            min-height: 28px;
        }

        &-shadow {
            position: absolute;
            right: -1px;
            top: 0;
            height: 100%;

            img {
                height: 100%;
            }

            @include below($mob) {
                display: none;
            }
        }
    }
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background-color: #ffffff;
    z-index: 10;

    @include below($mob) {
        flex-direction: column;
        height: 180px;
    }

    &__logo {
        display: block;
        margin: 0 60px;

        @include below($mob) {
            order: -1;
            margin: 10px 0 0 0;
        }
    }

    &__text-right {
        color: #5a6467;
        font-size: 19px;
        margin-left: 27px;
        text-align: right;

        @include below($mob) {
            text-align: center;
            margin: 7px 0;
        }
    }

    &__text-left {
        line-height: 22px;

        a {
            color: #5a6467;
            text-decoration: none;
        }

        @include below($mob) {
            text-align: center;
            margin-bottom: 10px;

            p {
                line-height: 25px;
            }
        }
    }
}

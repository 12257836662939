.pointer {
    cursor: pointer;
}

.login {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    width: 20em;
}

.admin-page-about button[type="submit"] {
    margin-top: 20px;
}

.admin-page-stories {
    .date {
        margin: 10px 0;
        font-style: italic;
    }
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-family: 'kelson sans rulight';
}
html {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
b, strong {
  font-weight: 700;
}
caption, th, td {
  text-align: left;
  font-weight: 400;
  vertical-align: middle;
}
q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: none;
}
a {
  outline: none;
  text-decoration: none;

  &:hover {
    color: unset !important;
    text-decoration: unset !important;
  }
}
img {
  border: none;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}
.nowrap {
  white-space: nowrap;

  * {
    white-space: nowrap;
  }
}

$full: 1599px;
$tab: 1023px;
$mob: 767px;
$de: 1280px;
$mbb: 357px;

$vertcl-min: 700px;
$vertcl-max: 530px;

@mixin below($value) {
  @media screen and (max-width: $value) {
    @content
  }
}

@mixin above($value) {
  @media screen and (min-width: $value) {
    @content
  }
}

@mixin below-height($value) {
  @media screen and (max-height: $value) {
    @content
  }
}

@mixin above-height($value) {
  @media screen and (min-height: $value) {
    @content
  }
}

.wrapper {
  display: block;
  margin: 0 auto;
  max-width: 934px;
  width: 100%;
  min-height: 100%;
  position: relative;

  @include above($de) {
    max-width:1100px;
  }

  @include above($full) {
    max-width:1470px;
  }

  @include below($tab) {
    max-width:728px;
  }

  @include below($mob) {
    max-width:100%;
  }
}


.slick-prev,
.slick-next {
  position: absolute;
  // bottom: -30px;
  // left: 25px;
  margin: auto;
  display: block;
  width: 20px;
  height: 60px;
  z-index: 10;
  left: 0;
  right: 0;
  font-size: 0;
  border: none;
  background-color: inherit;
  outline: 0 !important;
}

.slick-next {
  left: 100%;

  @include below($tab) {
    left: 200px;
    bottom: -100%;
  }
}

.slick-prev {
  right: 100%;

  @include below($tab) {
    right: 160px;
    bottom: -100%;
  }
}

.slick-prev:before,
.slick-next:before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -4px;
  width: 30px;
  height: 30px;
  border: solid #5a6467;
  border-width: 0 2px 2px 0;
  outline: 0 !important;

  @include below($tab) {
    width: 20px;
    height: 20px;
  }

}

.slick-prev:before {
  transform: rotate(135deg);
  left: -30px;
}

.slick-next:before {
  transform: rotate(315deg);
  right: -7px;
}

.slick-dots {
  bottom: -70px;
}

.slick-dots li button:before {
  font-size: 15px;
}

